import React from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import {
  BlockRate,
  ExtendedPlanInfoType,
  SolarFit,
} from '../../../utils/localStorage/localStorageTypes';
import { roundToDecimalPlacesAndPad } from '../../../utils/utils';

const style = require('./ThingsToKnowTable.module.scss');

const TAX = 1.1;

type ThingsToKnowTableProps = {
  className?: string;
  extendedPlanInfo: ExtendedPlanInfoType;
  [otherProps: string]: any;
};

const ThingsToKnowTable = (props: ThingsToKnowTableProps) => {
  const { className, extendedPlanInfo, ...rest } = props;

  const {
    dailySupplyCharge,
    usageRate,
    usageRateMin,
    usageRateMax,
    blockRates,
    controlledLoadDailyCharge1,
    controlledLoadDailyCharge2,
    controlledLoadRate1,
    controlledLoadRate2,
    solarFeedIn,
    greenPowerCharge,
    membershipFee,
    discountAmount,
    discountPercent,
    discountName,
  } = extendedPlanInfo;

  return (
    <div {...rest} className={classNames(style.planDetails, className)}>
      <h4>Things to know</h4>
      <table>
        <tbody>
          {!isNil(dailySupplyCharge) && (
            <tr>
              <td>Daily supply charge</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(dailySupplyCharge, 4) * 100
                ).toFixed(2)}
                c / day
              </td>
            </tr>
          )}
          {!isNil(usageRate) && (
            <tr>
              <td>Usage rate</td>
              <td>
                {(+roundToDecimalPlacesAndPad(usageRate, 4) * 100).toFixed(2)}c /
                kWh
              </td>
            </tr>
          )}
          {!isNil(usageRateMin) && !isNil(usageRateMax) && (
            <tr>
              <td>Usage rates</td>
              <td>
                {(+roundToDecimalPlacesAndPad(usageRateMin, 4) * 100).toFixed(2)}
                c -
                {(+roundToDecimalPlacesAndPad(usageRateMax, 4) * 100).toFixed(2)}
                c / kWh
              </td>
            </tr>
          )}
          {blockRates &&
            blockRates.map((blockRate: BlockRate, index: number) => {
              return (
                <tr key={`${index}`}>
                  <td>Block rate {blockRate.index + 1}</td>
                  <td>
                    {(
                      +roundToDecimalPlacesAndPad(
                        (+(blockRate.unitPrice ?? 0) / 100) * TAX,
                        4
                      ) * 100
                    ).toFixed(2)}
                    c / kWh
                  </td>
                </tr>
              );
            })}
          {solarFeedIn &&
            solarFeedIn.map((solarFeed: SolarFit, index: number) => {
              return (
                <tr key={`${index}`}>
                  <td>Solar feed-in-tariff {index + 1}</td>
                  <td>
                    {(
                      +roundToDecimalPlacesAndPad(
                        +(solarFeed.rate ?? 0) ,
                        4
                      ) * 100
                    ).toFixed(2)}
                    c / kWh
                  </td>
                </tr>
              );
            })}
          {!isNil(controlledLoadDailyCharge1) && (
            <tr>
              <td>CL 1 supply charge</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(controlledLoadDailyCharge1, 4) *
                  100
                ).toFixed(2)}
                c / day
              </td>
            </tr>
          )}
          {!isNil(controlledLoadDailyCharge2) && (
            <tr>
              <td>CL 2 supply charge</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(controlledLoadDailyCharge2, 4) *
                  100
                ).toFixed(2)}
                c / day
              </td>
            </tr>
          )}
          {!isNil(controlledLoadRate1) && (
            <tr>
              <td>CL 1 rate</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(controlledLoadRate1, 4) * 100
                ).toFixed(2)}
                c / kWh
              </td>
            </tr>
          )}
          {!isNil(controlledLoadRate2) && (
            <tr>
              <td>CL 2 rate</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(controlledLoadRate2, 4) * 100
                ).toFixed(2)}
                c / kWh
              </td>
            </tr>
          )}
          {!isNil(greenPowerCharge) && (
            <tr>
              <td>100% Green power usage rate</td>
              <td>
                {(
                  +roundToDecimalPlacesAndPad(greenPowerCharge, 4) * 100
                ).toFixed(2)}
                c / kWh
              </td>
            </tr>
          )}
          {!isNil(discountAmount) && (
            <>
              <tr>
                <td>Discount</td>
                <td>${roundToDecimalPlacesAndPad(discountAmount, 2)}</td>
              </tr>
              {!isNil(discountName) && (
                <tr>
                  <td colSpan={2} className={style.descriptiveCell}>
                    {discountName}
                  </td>
                </tr>
              )}
            </>
          )}
          {!isNil(discountPercent) && (
            <>
              <tr>
                <td>Discount</td>
                <td>{roundToDecimalPlacesAndPad(discountPercent, 4)}%</td>
              </tr>
              {!isNil(discountName) && (
                <tr>
                  <td colSpan={2} className={style.descriptiveCell}>
                    {discountName}
                  </td>
                </tr>
              )}
            </>
          )}
          {!isNil(membershipFee) && (
            <tr>
              <td>Membership fee</td>
              <td>${roundToDecimalPlacesAndPad(membershipFee, 2)} / year</td>
            </tr>
          )}
        </tbody>
      </table>

      <span className={style.infoText}>All prices are incl. GST</span>
    </div>
  );
};

ThingsToKnowTable.defaultProps = {
  className: '',
};

export default ThingsToKnowTable;
